import React, { Component } from 'react'
import { graphql } from 'gatsby'
import classnames from 'classnames'

import SEO from  'components/seo'
import ContentBlock from 'components/layouts/content-block'
import Hero from 'components/layouts/hero'
import Footer from 'components/footer/footer'
import AnimatedButton from 'components/buttons/animated-button'
import HorizontalDivider from 'components/layouts/horizontal-divider'
import { createLayout } from 'components/layouts'

import styles from './post.module.css'

class PostTemplate extends Component {
  render() {
    const currentPage = this.props.data.wordpressPost
    const acf = currentPage.acf.content_layout_post
    const featuredMedia = currentPage.featured_media
    const { job_post_list_title, job_post_description, job_post_bullets } = currentPage.acf

    let bulletPoints = []
    let featuredImage = {}

    if (featuredMedia && featuredMedia.localFile) {
      featuredImage = featuredMedia.localFile.childImageSharp.fluid
    }

    let flexibleContent = null

    if (acf) {
      flexibleContent = createLayout(acf)
    }

    if (job_post_bullets) {
      bulletPoints = job_post_bullets.map((bullet, index) => {
        return <li key={index}>{ bullet.bullet_item }</li>
      })
    }

    return (
      <>
        <SEO title={currentPage.title} yoast={currentPage.yoast_meta && currentPage.yoast_meta} />

        <Hero content={ currentPage.acf.blurb } featuredImage={featuredImage}>{ currentPage.acf.custom_title || currentPage.title }</Hero>
        { currentPage.content && <ContentBlock>{ currentPage.content }</ContentBlock> }

        <section className={classnames(styles.container, styles.alignBottom)}>
          <h2 className={styles.title}>A Question regarding the Job Posting Mentioned Here?</h2>
          <div className={styles.buttonWrapper}>
            <AnimatedButton mailTo='info@arraydentalgroup.com' message='Apply to this position' />
          </div>
        </section>

        <HorizontalDivider />

        <section className={styles.container}>
          <div className={styles.content} dangerouslySetInnerHTML={{ __html: job_post_description }}></div>

          <div className={styles.requirements}>
            <h3>{ job_post_list_title }</h3>
            <ul>
              { bulletPoints }
            </ul>
          </div>
        </section>

        { flexibleContent }

        <Footer />
      </>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_twitter_title
        yoast_wpseo_website_name
        yoast_wpseo_person_name
        yoast_wpseo_metadesc
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_image
        yoast_wpseo_facebook_description
        yoast_wpseo_company_or_person
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_social_defaults {
          facebook_site
          instagram_url
          linkedin_url
          myspace_url
          og_default_image
          og_default_image_id
          og_frontpage_desc
          og_frontpage_image
          og_frontpage_image_id
          og_frontpage_title
          opengraph
          pinterest_url
          pinterestverify
          twitter
          twitter_card_type
          twitter_site
          wikipedia_url
          youtube_url
        }
      }
      date(formatString: "MMMM DD, YYYY")
      #featured_media {
      #  localFile {
       #   childImageSharp {
        #    fluid(maxWidth: 650, quality: 100) {
        #      ...GatsbyImageSharpFluid_noBase64
        #    }
        #  }
       # }
      #}
      acf {
        blurb
        custom_title
        job_post_list_title
        job_post_description
        job_post_bullets {
          bullet_item
        }
        #content_layout_post {
         # __typename
          # ... on WordPressAcf_image_collage {
          #   id
          #   alternative_arrangement
          #   image_gallery {
          #     localFile {
          #       childImageSharp {
          #         fluid(maxWidth: 800, quality: 90) {
          #           ...GatsbyImageSharpFluid_noBase64
          #         }
          #       }
          #     }
          #   }
          # }
         # ... on WordPressAcf_giant_button {
         #   id
          #  page_link {
          #    target
           #   title
           #   url
          #  }
          #  title
          #  subtitle
         # }
         # ... on WordPressAcf_borderless_content {
          #  id
          #  content
          #  subtitle
          #  title
          #  two_columns
          #}
          #... on WordPressAcf_horizontal_divider {
          #  id
          #  color
         # }
       # }
      }
    }

    site {
      id
      siteMetadata {
        title
      }
    }
  }
`